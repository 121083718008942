<template>
   <div v-if="true" class="smart-wrap" >
      <div class="col-md-6" style="padding: 20px;">
         <h1>Preview</h1>
         <div class="input-wrap col-6">
            <select name="" id="" v-model="widthDisplayWebSite" v-on:change="renderContainer()" >
               <option value="100%">Desk</option>
               <option value="992px">Laptop</option>
               <option value="767px">Tablet</option>
               <option value="450px">Phone</option>
            </select>
         </div>
      </div>
      <div class="col-md-6" style="display: flex;padding: 20px;align-items: center; justify-content: flex-end;">
         <router-link class="btn-g show blue" :to="{ path:'/smartmaker',query: { name:'SMLUP', PK:record.PK, SK:record.SK, } }" style="margin: 0 10px;"  >
            Design
         </router-link>
         <router-link class="btn-g show gray" :to=" { path:'/webpreview',query: {  sml:record.SK,}  }"  style="margin: 0 10px;" >
            Input Data
         </router-link>
         <router-link class="btn-g show green" :to=" { path:'/smartmaker',query: { name:'SMLUP', PK:record.PK, SK:record.SK, publish:true } }"  style="margin: 0 10px;" >
            Publish
         </router-link>
      </div>
      <div class="col-md-12">
         <div id="container-web" :style="'width:'+ widthDisplayWebSite+';margin:0 auto;     justify-content: flex-end;'">
            <paper-layout id="wrap-menu"  :srcStyle="webSite" v-if="renderWeb "  :idioms="webSite.idioms" :language="webSite.language" :layout="webSite.layout" :models="webSite.binds" :palette="webSite.palette" :paperPermissions="{usersCanEdit:[], usersCanRead:[],groupsCanEdit:[],groupsCanRead:[]}"/>
         </div>
      </div>
   </div>
</template>
<script>


//Tools
import Vuex from "vuex";
import { Auth, API, Storage } from "aws-amplify";
import { popUp, loading, validationInputJSON, processLayout } from "../../store/tools";

//Components
import PaperDisplayer from "../../components/papers/PaperDisplayer.vue";
import PaperLayout from "../../components/papers/PaperLayout.vue";

//Back End
import { smd_getRecord2, smd_listEntityObj, smd_getWebPage, smd_generateGraphicPost, smd_getRecordObj, smd_generateWebSite, smd_applyCSS } from "../../graphql/queries";
import { smd_updateSmartLayout, smd_updateAttributes, smd_updateSmartRecord } from "../../graphql/mutations";
export default {
   name: "smart-maker",
   components: {
      PaperDisplayer,
      PaperLayout,
   },
   data() {
      return {
         //Record
         record: undefined,
         webSite: undefined,
         renderWeb: false,
         widthDisplayWebSite:'100%'
      }
   },
   created() {
      this.record = this.$router.currentRoute.value.query
      this.webPreview()
      setTimeout(() => {
         
      }, 100);
   },
   methods: {
      ...Vuex.mapMutations(['setCustomLayout', 'setWebSite', 'applyCustomItem', 'setgRecord']),

      //Action PostZ
      async webPreview(preview) {
         console.clear()
         loading('show', 'webPreview')
         let pushData = {};
         let pullData = ''
         try {
            pushData = {
               orgPK: this.record.PK,
               SMLPK: this.record.PK,
               SMLSK: this.record.SK
            }
            console.log('pushData Get Layout:', pushData);
            pullData = await API.graphql({
               query: smd_getWebPage,
               variables: pushData
            });
            this.webSite = {}
            pullData = pullData.data.smd_getWebPage
            pullData = JSON.parse(pullData.data)
            for (let index = 0; index < JSON.parse(pullData.SML).length; index++) {
               const element = JSON.parse(pullData.SML)[index];
               try {
                  if (element.attributeType == 'L') {
                     element.attributeValue = JSON.parse(element.attributeValue)
                  }
               } catch (error) {
                  console.log(error);
               }
               this.webSite[element.attributeName] = element.attributeValue
            }
            this.webSite.binds = pullData.binds
            this.webSite.layout = pullData.layout
            this.setWebSite(this.webSite)
            console.log('Web Site Data:', this.webSite);
            document.head.insertAdjacentHTML("beforeend", this.webSite.fontScript)
            const replacements = {
               'var(--color0)': this.webSite.palette[0],
               'var(--color1)': this.webSite.palette[1],
               'var(--color2)': this.webSite.palette[2],
               'var(--color3)': this.webSite.palette[3],
               'var(--color4)': this.webSite.palette[4],
               'var(--color5)': this.webSite.palette[5],
               'var(--color6)': this.webSite.palette[6],
               'var(--font0)': this.webSite.fonts[0],
               'var(--font1)': this.webSite.fonts[1],
               'var(--font2)': this.webSite.fonts[2],
               'var(--font3)': this.webSite.fonts[3],
            };

            // Utilizar una expresión regular para buscar las palabras a reemplazar y la función replace() para hacer el reemplazo
            if (this.webSite.class) {
               
               const replacedString = this.webSite.class.replace(/var\(--(color|font)\d\)/g, match => replacements[match]);
               console.log(replacedString);
               document.head.insertAdjacentHTML("beforeend", '<style>'+replacedString+'</style>')
               setTimeout(() => {
                  this.renderWeb = true
               }, 100);
            }
            console.clear()
            console.log(this.widthDisplayWebSite);
            if (this.widthDisplayWebSite == '450px') {
               
               // Definir la expresión regular para un bloque @media
               const mediaRegex = /@media[^{]+\{([\s\S]+?})\s*}/g;
   
               // Encontrar todas las coincidencias de bloques @media
               let match;
               while ((match = mediaRegex.exec(this.webSite.class)) !== null) {
                  // Extraer el contenido del bloque @media
                  const mediaBlockContent = match[1];
                  console.log(mediaBlockContent);
                  document.head.insertAdjacentHTML("beforeend", '<style>'+mediaBlockContent+'</style>')
               }
            } 
            
         } catch (error) {
            console.log(error);
         }
         loading('hide', 'webPreview')
      },
      renderContainer(){
         loading('show', 'webPreview')
         this.renderWeb = false
         
         setTimeout(() => {
            this.renderWeb = true
            loading('hide', 'webPreview')
         }, 100);
         setTimeout(() => {
            const existingDiv = document.getElementById('wrap-menu');
            console.log(existingDiv);
            // Create a new div element
            const menuDiv = document.getElementById('collapseMenu-3');
            console.log(menuDiv);
            // Insert the new div element after the existing div element
            existingDiv.insertAdjacentElement('afterend', menuDiv);
            //menuDiv.remove();
         }, 500);

      }
   },
   computed: {
      ...Vuex.mapState([
         "organizationID",
         "userPermissions",
         "permissions",
         "preloadLists",
         "customLayout",
         "customItem",
         'elementList',
         'srcFonts',
         'comboFonts',
      ]),
   },
}
</script>
<style>
.menuResponsive{
	position: absolute;
	top: 0;
	background: #fff;
	z-index: 999;
	height: 100vh;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	align-content: center;
	opacity: 1;
	-webkit-transition: all 0.3s ease 0s;
	-moz-transition: all 0.3s ease 0s;
	-ms-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
	overflow: hidden;
}
.menuResponsive.hide{
	opacity: 0;
	z-index: -999;
	-webkit-transition: all 0.3s ease 0s;
	-moz-transition: all 0.3s ease 0s;
	-ms-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}
.headerMenu{
	width: 100%;	
	display: flex;
	align-items: center;
	justify-content: space-around;
	flex-wrap: nowrap;
}
.headerMenu .menuLogo{
	width: 200px;
}
.headerMenu .menuIcon{
	width: 50px;
}
</style>